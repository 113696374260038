import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import Header from '../components/Header'
import { Heading1, Heading4 } from '../components/Typography'
import Link from 'gatsby-link'
import { URL_PATHS } from '../constants/urlPaths'

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404 - Not found" />
    <Header />
    <Container
      textAlign="center"
      pt={['1rem', '10rem']}
      pb={['4rem', '10rem']}
      minHeight={['61vh', '91.125vh']}
    >
      <Inner maxWidth="60rem">
        <Container bg="teal" mb={['3rem', '6rem']}>
          <Container
            maxWidth="28.75rem"
            ml="auto"
            mr="auto"
            pt="3rem"
            pb="3rem"
          >
            <Img fluid={data.pic404.childImageSharp.fluid} objectFit="cover" />
          </Container>
        </Container>
        <Container mb="1.5rem">
          <Heading1 color="teal">404 - Not found</Heading1>
        </Container>
        <Container>
          <Heading4 color="grey">
            We're sorry but the page you are looking for does not exist. You may
            have followed an old link or typed in the address incorrectly.
          </Heading4>
        </Container>
        <Container mt="1rem">
          <Heading4 color="grey">
            Please check the address or try our{' '}
            <Link to={URL_PATHS.HOME}>homepage</Link>. Thank you!
          </Heading4>
        </Container>
      </Inner>
    </Container>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query {
    pic404: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
